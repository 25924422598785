<template>
  <div>
    <h1 class="no-print" :class="$style.pageTitle">{{ pageTitle }}</h1>
    <div class="row" :class="{'print-tab':isPrintTab}">
      <div class="col-lg-12">
        <div class="card">

          <!-- タブ -->
          <div class="card-header no-print" style="padding: 8px 0 0;box-shadow: 0px 3px 2px #00000029;">
            <a-tabs :class="$style.navBar" :activeKey="tabIndex" @tabClick="tabClick">
              <a-tab-pane key="1">
                <span slot="tab">
                  <a-icon type="car"/>乗車前
                </span>
              </a-tab-pane>
              <a-tab-pane key="2">
                <span slot="tab">
                  <a-icon type="car"/>乗車後
                </span>
              </a-tab-pane>
              <a-tab-pane key="3">
                <span slot="tab">
                  <a-icon type="printer"/>印刷
                </span>
              </a-tab-pane>
            </a-tabs>
          </div><!-- /.card-header -->

          <div class="card-body">
            <!-- 検索条件ボタン -->
            <a-form class="no-print" layout="inline" style="margin-bottom: 20px;">
              対象支店：
              <a-select
                show-search
                :value="branchId"
                style="width: 30%; margin-right: 20px;"
                @change="targetBranchChange"
                :filter-option="filterOption"
              >
                <a-select-option
                  v-for="item in branches"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
              対象日：
              <a-button @click="yesterday()" style="margin-right: 3px;">前日</a-button>
              <a-button @click="today()" style="margin-right: 3px;">今日</a-button>
              <a-button @click="tomorrow()" style="margin-right: 7px;">翌日</a-button>
              <a-config-provider :locale="locale">
                <a-date-picker placeholder="対象日" v-model="targetDate" />
              </a-config-provider>
              <a-button type="primary" @click="refreshList" style="margin-left: 10px;" :class="$style.filledBtn">取得</a-button>
              <a-button v-if="isPrintTab" type="primary" @click="print" style="margin-left: 10px; margin-right: 20px;" :class="$style.filledBtn">印刷</a-button>
            </a-form>
            <p v-if="isPrintTab" class="no-print text-right">※印刷時に点呼が全て表示されない場合は、印刷の設定を横向きにして試してみてください。</p>

            <div v-if="loading" class="text-center" style="margin-top: 50px; margin-bottom: 50px;">
              <a-spin tip="Loading..." />
            </div>

            <div v-if="!loading">
              <h3>{{ subTitle }}</h3>
              <div :class="$style.tableTopButtons" class="clearfix" v-if="!isPrintTab">
                <p>
                  <a-button type="primary" @click="rollCallExecute" :class="$style.filledBtn" :disabled="isRollCallExeButtonDisabled">
                    確認済みにする
                  </a-button>
                  <router-link :to="{ name: 'alcoholCheckUnconfirmed', params: { tabIndex: tabIndex, branchId: branchId } }">
                    <a-button type="primary" :class="$style.filledBtn" style="margin-left: 7px;">
                      未確認一覧
                    </a-button>
                  </router-link>
                </p>
                <p>
                  <a-button type="primary" @click="createAlcoholCheck" :class="$style.filledBtn">作成</a-button>
                </p>
              </div>

              <!-- 一覧 -->
              <a-table
                :style="{ 'min-height':  minHeight + ' !important'}"
                :columns="columns"
                :data-source="alcoholCheckDataList"
                :pagination="false"
                :bordered="isPrintTab"
                rowKey="id"
                :rowClassName="
                  (r, i) => i % 2 === 0 && r.manual_flag === 1 ? $style.manual_row + ' ' + $style.stripe : i % 2 === 0 ? $style.stripe : r.manual_flag === 1 ? $style.manual_row : ''
                "
              >
                <span slot="confirmedTitle">
                  <a-checkbox :indeterminate="isConfIndeterminate" :checked="isConfAllChecked" @change="onChangeConfirmedAll">
                    確認
                  </a-checkbox>
                </span>

                <span slot="confirmed" slot-scope="id, record">
                  <div v-if="!record.confirmed">
                    <a-checkbox @change="onChangeConfirmedRow($event, record.id)" :checked="isConfRowChecked(record.id)" />
                  </div>
                  <span v-if="record.confirmed">確認済</span>
                </span>
                <span slot="branch_name" slot-scope="id, record">
                  {{ record.branch_name }}
                </span>
                <span slot="driver" slot-scope="id, record">
                  {{ record.user_name }}<br v-if="isPrintTab"/> ({{ record.vehicle_name }})
                </span>
                <span slot="item_allow_ride" slot-scope="id, record">
                  {{ itemAllowRide[record.item_allow_ride] }}
                </span>
                <span slot="call_datetime" slot-scope="id, record">
                  {{ showDateTime(record.call_datetime) }}
                </span>
                <span slot="item_alcohol_detector" slot-scope="id, record">
                  {{ itemAlcoholDetector[record.item_alcohol_detector] }}
                </span>
                <span slot="item_drinking" slot-scope="id, record">
                  <span :class="[record.alcohol_measurement_result >= alcoholThreshold ? $style.colorRed : '']">{{ itemDrinking[record.item_drinking] }}</span>
                </span>
                <span slot="alcohol_measurement_result" slot-scope="id, record">
                  <span :class="[record.alcohol_measurement_result >= alcoholThreshold ? $style.colorRed : '']"><i v-if="record.detector === 1" class="fa fa-bluetooth" style="color: #146DAD; margin-right: 3px;"></i>{{ record.alcohol_measurement_result }}</span>
                </span>
                <span slot="item_roll_call_type" slot-scope="id, record">
                  {{ itemRollCallType[record.item_roll_call_type] }}
                </span>
                <span slot="roll_call_executer_id" slot-scope="id, record">
                  {{ record.executer_name }}
                </span>
                <span slot="update" slot-scope="id, record">
                  <a-button v-if="myRole <= 2 || myRollCallAble === 1" @click="updateAlcoholCheck(record.id)" style="padding: 0 10px; margin-right: 4px;">編集</a-button>
                  <a-button @click="updateAlcoholCheckSelf(record.id)" style="padding: 0 6px;">自由項目編集</a-button>
                </span>
                <span slot="call_datetime_end" slot-scope="id, record">
                  {{ showDateTime(record.call_datetime_end) }}
                </span>
                <span slot="item_alcohol_detector_end" slot-scope="id, record">
                  {{ itemAlcoholDetector[record.item_alcohol_detector_end]  }}
                </span>
                <span slot="item_drinking_end" slot-scope="id, record">
                  {{ itemDrinking[record.item_drinking_end]  }}
                </span>
                <span slot="item_roll_call_type_end" slot-scope="id, record">
                  {{ itemRollCallType[record.item_roll_call_type_end]  }}
                </span>
                <span slot="roll_call_executer_id_end" slot-scope="id, record">
                  {{ record.executer_name_end }}
                </span>
              </a-table>

              <p v-if="!isPrintTab" class="text-right" style="margin-top: 20px;">
                <span style="padding: 3px;" :class="$style.manual_row">この色の行は手動で作成したものです。</span>
              </p>
            </div>
          </div><!-- /.card-body -->
        </div><!-- /.card -->

        <div v-if="isPrintTab" class="card no-print">
          <div class="card-header" style="padding: 16px !important;">
            <h3>CSV出力</h3>
          </div>
          <div class="card-body no-print">
            <a-form layout="inline" style="margin-bottom: 20px;">
              <p>
                対象支店：
                <a-select
                  show-search
                  :value="branchId"
                  style="width: 30%; margin-right: 20px;"
                  @change="targetBranchChange"
                  :filter-option="filterOption"
                >
                  <a-select-option
                    v-for="item in branches"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </p>
              <p>
                対象期間: <a-date-picker :value="exportDateStart" @change="changeExportDateStart" /> 〜 <a-date-picker style="margin-right: 20px;" :value="exportDateEnd" @change="changeExportDateEnd" />
              </p>
              <a-button type="primary" @click="csvExport" style="margin-left: 10px;" :class="$style.filledBtn" :loading="exportLoading">出力</a-button>
            </a-form>
          </div>
        </div>
      </div>
    </div><!-- /.row -->

    <!-- 作成/編集モーダル -->
    <a-modal
      :title="modalText"
      :visible="modalVisible"
      @cancel="handleCancelAlcoholCheckModal"
    >
      <div>
        <a-form-model
          :model="alcoholCheckForm"
        >
          <a-form-model-item v-if="myRole === 0" ref="item_allow_ride" label="乗車 前・後" prop="type">
            <a-radio-group v-model="alcoholCheckForm.type">
              <a-radio :value="0">乗車前</a-radio>
              <a-radio :value="1">乗車後</a-radio>
            </a-radio-group>
          </a-form-model-item>

          <a-form-model-item v-if="myRole === 0" ref="callDate" label="点呼日" prop="onCallDate">
            <a-date-picker v-model="onCallDate" />
          </a-form-model-item>

          <a-form-model-item v-if="alcoholCheckCreate || myRole === 0" ref="callDate" label="点呼確認日" prop="callDate">
            <a-date-picker v-model="createCallDate" />
          </a-form-model-item>

          <a-form-model-item v-if="alcoholCheckCreate || myRole === 0" ref="callTime" label='点呼確認時刻' prop="callTime">
            <a-time-picker v-model="createCallTime" />
          </a-form-model-item>

          <a-form-model-item v-if="alcoholCheckCreate || myRole === 0" ref="driver" label="運転者" prop="driver">
            <a-select
              show-search
              :value="alcoholCheckForm.user_id"
              @change="targetDriverChange"
              :filter-option="filterOption"
            >
              <a-select-option
                key="0"
                value="0"
              >
                未選択
              </a-select-option>
              <a-select-option
                v-for="item in drivers"
                :key="item.id"
                :value="item.id"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>

          <a-form-model-item v-if="alcoholCheckCreate || myRole === 0" ref="vehicle" label="車両" prop="vehicle">
            <a-select
              show-search
              :value="alcoholCheckForm.vehicle_id"
              @change="targetVehicleChange"
              :filter-option="filterOption"
            >
              <a-select-option
                key="0"
                value="0"
              >
                未選択
              </a-select-option>
              <a-select-option
                v-for="item in vehicles"
                :key="item.id"
                :value="item.id"
              >
                {{ item.branch_name}}: {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>

          <a-form-model-item v-if="myRole === 0" ref="roll_call_executer_id" label="点呼執行者" prop="roll_call_executer_id">
            <a-select
              show-search
              :value="alcoholCheckForm.roll_call_executer_id"
              @change="targetRollCallExecuterChange"
              :filter-option="filterOption"
            >
              <a-select-option
                key="0"
                value="0"
              >
                未選択
              </a-select-option>
              <a-select-option
                v-for="item in drivers"
                :key="item.id"
                :value="item.id"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>

          <a-form-model-item v-if="isStartTab" ref="item_allow_ride" label="乗車可否" prop="item_allow_ride">
            <a-radio-group v-model="alcoholCheckForm.item_allow_ride">
              <a-radio :value="0">未選択</a-radio>
              <a-radio :value="1">許可</a-radio>
              <a-radio :value="2">非許可</a-radio>
            </a-radio-group>
          </a-form-model-item>

          <a-form-model-item ref="item_alcohol_detector" label="アルコール検知器の有無" prop="item_alcohol_detector">
            <a-radio-group v-model="alcoholCheckForm.item_alcohol_detector">
              <a-radio :value="0">未選択</a-radio>
              <a-radio :value="1">有</a-radio>
              <a-radio :value="2">無</a-radio>
            </a-radio-group>
          </a-form-model-item>

          <a-form-model-item ref="item_drinking" label="酒気帯びの有無" prop="item_drinking">
            <a-radio-group v-model="alcoholCheckForm.item_drinking">
              <a-radio :value="0">未選択</a-radio>
              <a-radio :value="1">有</a-radio>
              <a-radio :value="2">無</a-radio>
            </a-radio-group>
          </a-form-model-item>

          <a-form-model-item ref="item_roll_call_type" label="点呼方法" prop="item_roll_call_type">
            <a-radio-group v-model="alcoholCheckForm.item_roll_call_type">
              <a-radio :value="0">未選択</a-radio>
              <a-radio :value="1">対面</a-radio>
              <a-radio :value="2">ビデオ通話</a-radio>
              <a-radio :value="3">電話</a-radio>
              <a-radio :value="4">その他</a-radio>
            </a-radio-group>
          </a-form-model-item>

          <a-form-model-item v-if="myRole === 0 || canUpdateAlcoholMeasurementResult === 1" ref="alcohol_measurement_result" label="アルコール濃度" prop="alcohol_measurement_result">
            <a-input-number v-model="alcoholCheckForm.alcohol_measurement_result" :min="0" :max="100"/>
          </a-form-model-item>

          <div v-for="item in freeItemDefList" :key="item.id">
            <a-form-model-item :label="item.name" v-if="(isStartTab && item.show_timing !== 2) || (isEndTab && item.show_timing !== 1)" :rules="[{ required: item.required_item === 1, message: '必須項目です。' }]">
              <div v-if="item.choice">
                <a-button v-for="choice in item.choice.split('||')" :key="choice" @click="inputFreeItem(item.id, choice)">{{ choice }}</a-button>
              </div>
              <a-input v-model="alcoholCheckForm.free_items[item.id]" />
            </a-form-model-item>
          </div>
        </a-form-model>
      </div>
      <template slot="footer">
        <div class="clearfix">
          <div class="float-right">
            <a-button key="back" @click="handleCancelAlcoholCheckModal">
              キャンセル
            </a-button>
            <a-button key="submit" type="primary" :loading="alcoholCheckConfirmLoading" @click="handleOkAlcoholCheck" :class="$style.filledBtn">
              {{ modalText }}
            </a-button>
          </div>
          <div class="float-left" v-if="isDeletable">
            <a-button style="background-color: #EF5350 !important;" :loading="alcoholCheckConfirmLoading" @click="handleDeleteAlcoholCheck" :class="$style.filledBtn">
              削除
            </a-button>
          </div>
        </div>
      </template>
    </a-modal>

    <a-modal
      title="自由項目編集"
      :visible="modalVisibleSelf"
      @cancel="handleCancelAlcoholCheckSelfModal"
    >
      <div>
        <a-form-model
          :model="alcoholCheckForm"
        >
          <div v-for="item in freeItemDefList" :key="item.id">
            <a-form-model-item v-if="item.edit_self_flag && ((isStartTab && item.show_timing !== 2) || (isEndTab && item.show_timing !== 1))" :label="item.name">
              <div v-if="item.choice">
                <a-button v-for="choice in item.choice.split('||')" :key="choice" @click="inputFreeItem(item.id, choice)">{{ choice }}</a-button>
              </div>
              <a-input v-model="alcoholCheckForm.free_items[item.id]" />
            </a-form-model-item>
          </div>
        </a-form-model>
      </div>
      <template slot="footer">
        <div class="clearfix">
          <div class="float-right">
            <a-button key="back" @click="handleCancelAlcoholCheckSelfModal">
              キャンセル
            </a-button>
            <a-button key="submit" type="primary" :loading="alcoholCheckConfirmLoading" @click="handleOkAlcoholCheckSelf" :class="$style.filledBtn">
              更新
            </a-button>
          </div>
        </div>
      </template>
    </a-modal>
  </div>
</template>
<style lang="scss" module>
  @import './style.module.scss';
</style>
<script>
import Vue from 'vue'
import moment from 'moment'
import jaJa from 'ant-design-vue/es/locale/ja_JP'

export default {
  data() {
    return {
      locale: jaJa,
      targetDate: moment(), // 対象日 (検索条件)
      gotDate: '', // 一覧データを取得した日 (YYYY/MM/DD)
      gotBranchName: '', // 一覧データを取得した支店名
      loading: true,
      itemType: ['前', '後'],
      itemAllowRide: ['未選択', '許可', '非許可'],
      itemAlcoholDetector: ['未選択', '有', '無'],
      itemDrinking: ['未選択', '有', '無'],
      itemRollCallType: ['未選択', '対面', 'ビデオ通話', '電話', 'その他'],
      dataIndexPrefixOfFreeItem: 'freeItemAlcoholCheck',
      dataIndexPrefixOfFreeItemEnd: 'freeItemAlcoholCheckEnd',
      tabIndex: '1',
      alcoholThreshold: 100,
      concatVehicleNameAndNo: 1,
      canUpdateAlcoholMeasurementResult: 0,
      minHeight: 'max-content', // * 96

      myRole: 3,
      myRollCallAble: 0,
      alcoholMeasurementHide: 0,
      createCallDate: moment(),
      createCallTime: moment(),
      onCallDate: moment(), // マスター用。点呼の日付
      branchId: null,
      branchIdSelected: null,
      branches: [],
      drivers: [],
      vehicles: [],
      alcoholCheckIdsToConfirm: [], // 確認済みにするID

      // 一覧データリスト
      alcoholCheckDataList: [],
      // 自由項目定義リスト
      freeItemDefList: [],
      modalText: '作成',
      modalVisible: false,
      modalVisibleSelf: false,
      alcoholCheckConfirmLoading: false,
      alcoholCheckCreate: false,
      alcoholCheckForm: {
        type: 0,
        vehicle_id: null,
        user_id: null,
        roll_call_executer_id: null,
        item_alcohol_detector: 0,
        item_drinking: 0,
        item_roll_call_type: 0,
        item_allow_ride: 0,
        alcohol_measurement_result: 0,
        free_items: {},
      },
      targetAlcoholCheckId: 0,
      isDeletable: false,
      alcoholCheckDefaultValue: {},

      exportLoading: false,
      exportDateStart: moment(),
      exportDateEnd: moment(),
      isExportDateRangeValid: true,
    }
  },
  computed: {
    // タイトルラベル
    pageTitle() {
      const title = '点呼記録簿'
      if (this.isStartTab) {
        return title + '：乗車前'
      } else if (this.isEndTab) {
        return title + '：乗車後'
      } else {
        return title
      }
    },
    // サブタイトルラベル
    subTitle() {
      let label = this.gotDate + ' の ' + this.gotBranchName
      if (this.isStartTab) {
        label += ' の乗車前 '
      } else if (this.isEndTab) {
        label += ' の乗車後 '
      } else {
        label += ' の'
      }
      label += '点呼記録簿'
      return label
    },
    // 乗車前タブかどうか
    isStartTab() {
      return this.tabIndex === '1'
    },
    // 乗車後タブかどうか
    isEndTab() {
      return this.tabIndex === '2'
    },
    // 印刷タブかどうか
    isPrintTab() {
      return this.tabIndex === '3'
    },
    gotDateHyphen() {
      return this.targetDate.format('YYYY-MM-DD')
    },
    // 一覧ヘッダー
    columns() {
      if (this.isStartTab || this.isEndTab) {
        const list = [
          {
            dataIndex: 'confirmed',
            slots: { title: 'confirmedTitle' },
            scopedSlots: { customRender: 'confirmed' },
            sorter: false,
          },
          {
            title: '支店名',
            dataIndex: 'branch_name',
            scopedSlots: { customRender: 'branch_name' },
            sorter: false,
          },
          {
            title: '運転手名（車両名）',
            dataIndex: 'driver',
            scopedSlots: { customRender: 'driver' },
            sorter: false,
          },
          {
            title: '乗車可否',
            dataIndex: 'item_allow_ride',
            scopedSlots: { customRender: 'item_allow_ride' },
            sorter: false,
          },
          {
            title: '点呼日時',
            dataIndex: 'call_datetime',
            scopedSlots: { customRender: 'call_datetime' },
            sorter: false,
          },
          {
            title: 'アルコール検知器の有無',
            dataIndex: 'item_alcohol_detector',
            scopedSlots: { customRender: 'item_alcohol_detector' },
            sorter: false,
          },
          {
            title: '酒気帯びの有無',
            dataIndex: 'item_drinking',
            scopedSlots: { customRender: 'item_drinking' },
            sorter: false,
          },
          {
            title: 'アルコール濃度',
            dataIndex: 'alcohol_measurement_result',
            scopedSlots: { customRender: 'alcohol_measurement_result' },
            sorter: false,
          },
          {
            title: '点呼方法',
            dataIndex: 'item_roll_call_type',
            scopedSlots: { customRender: 'item_roll_call_type' },
            sorter: false,
          },
          {
            title: '点呼執行者',
            dataIndex: 'roll_call_executer_id',
            scopedSlots: { customRender: 'roll_call_executer_id' },
            sorter: false,
          },
          {
            title: '編集',
            dataIndex: '',
            scopedSlots: { customRender: 'update' },
            sorter: false,
          },
        ]

        if (this.isEndTab) {
          // 乗車後の場合は乗車可否を削除
          list.splice(3, 1)
        }

        // 自由項目を挿入
        let index = this.isStartTab ? 8 : 7
        this.freeItemDefList.forEach(item => {
          if ((this.isStartTab && item.show_timing !== 2) || (this.isEndTab && item.show_timing !== 1)) {
            list.splice(index, 0,
              {
                title: item.name,
                dataIndex: this.dataIndexPrefixOfFreeItem + item.id,
                sorter: false,
              },
            )
            index++
          }
        })

        if (this.alcoholMeasurementHide) {
          if (this.isStartTab) list.splice(7, 1)
          else if (this.isEndTab) list.splice(6, 1)
        }

        return list
      } else {
        const list = [
          {
            title: '支店名',
            dataIndex: 'branch_name',
            scopedSlots: { customRender: 'branch_name' },
            sorter: false,
            class: 'company-name-cell',
          },
          {
            title: '運転手名（車両名）',
            dataIndex: 'driver',
            scopedSlots: { customRender: 'driver' },
            sorter: false,
            class: 'driver-name-cell',
          },
          // 乗車前
          {
            title: '乗車前点呼',
            class: 'border-left-thick',
            children: [
              {
                title: '乗車可否',
                dataIndex: 'item_allow_ride',
                scopedSlots: { customRender: 'item_allow_ride' },
                sorter: false,
                class: 'border-left-thick',
              },
              {
                title: '点呼日時',
                dataIndex: 'call_datetime',
                scopedSlots: { customRender: 'call_datetime' },
                sorter: false,
              },
              {
                title: 'アルコール検知器の有無',
                dataIndex: 'item_alcohol_detector',
                scopedSlots: { customRender: 'item_alcohol_detector' },
                sorter: false,
              },
              {
                title: '酒気帯びの有無',
                dataIndex: 'item_drinking',
                scopedSlots: { customRender: 'item_drinking' },
                sorter: false,
              },
              {
                title: 'アルコール濃度',
                dataIndex: 'alcohol_measurement_result',
                scopedSlots: { customRender: 'alcohol_measurement_result' },
                sorter: false,
              },
              {
                title: '点呼方法',
                dataIndex: 'item_roll_call_type',
                scopedSlots: { customRender: 'item_roll_call_type' },
                sorter: false,
              },
              {
                title: '点呼執行者',
                dataIndex: 'roll_call_executer_id',
                scopedSlots: { customRender: 'roll_call_executer_id' },
                sorter: false,
              },
            ],
          },
          // 乗車後
          {
            title: '乗車後点呼',
            class: 'border-left-thick',
            children: [
              {
                title: '点呼日時',
                dataIndex: 'call_datetime_end',
                scopedSlots: { customRender: 'call_datetime_end' },
                sorter: false,
                class: 'border-left-thick',
              },
              {
                title: 'アルコール検知器の有無',
                dataIndex: 'item_alcohol_detector_end',
                scopedSlots: { customRender: 'item_alcohol_detector_end' },
                sorter: false,
              },
              {
                title: '酒気帯びの有無',
                dataIndex: 'item_drinking_end',
                scopedSlots: { customRender: 'item_drinking_end' },
                sorter: false,
              },
              {
                title: 'アルコール濃度',
                dataIndex: 'alcohol_measurement_result_end',
                scopedSlots: { customRender: 'alcohol_measurement_result_end' },
                sorter: false,
              },
              {
                title: '点呼方法',
                dataIndex: 'item_roll_call_type_end',
                scopedSlots: { customRender: 'item_roll_call_type_end' },
                sorter: false,
              },
              {
                title: '点呼執行者',
                dataIndex: 'roll_call_executer_id_end',
                scopedSlots: { customRender: 'roll_call_executer_id_end' },
                sorter: false,
              },
            ],
          },
        ]

        // 自由項目を挿入
        const startChildren = list[2]['children']
        const endChildren = list[3]['children']
        let index = 5
        let indexEnd = 4
        this.freeItemDefList.forEach(item => {
          if (item.show_timing !== 2) {
            startChildren.splice(index, 0,
              {
                title: item.name,
                dataIndex: this.dataIndexPrefixOfFreeItem + item.id,
                sorter: false,
              },
            )
            index++
          }
          if (item.show_timing !== 1) {
            endChildren.splice(indexEnd, 0,
              {
                title: item.name,
                dataIndex: this.dataIndexPrefixOfFreeItemEnd + item.id,
                sorter: false,
              },
            )
            indexEnd++
          }
        })

        if (this.alcoholMeasurementHide) {
          startChildren.splice(4, 1)
          endChildren.splice(3, 1)
        }

        return list
      }
    },
    // 「確認済みにする」ボタンがdisabledか？
    isRollCallExeButtonDisabled() {
      return !this.alcoholCheckIdsToConfirm.length
    },
    // テーブルヘッダーの確認チェックボックスが未確定か？
    isConfIndeterminate() {
      return this.alcoholCheckIdsToConfirm.length > 0 &&
        this.unconfirmedAlcoholCheckIds.length > this.alcoholCheckIdsToConfirm.length
    },
    // テーブルヘッダーの確認チェックボックスが全て選択されているか？
    isConfAllChecked() {
      return this.alcoholCheckIdsToConfirm.length > 0 &&
        this.unconfirmedAlcoholCheckIds.length === this.alcoholCheckIdsToConfirm.length
    },
    // 一覧行の確認チェックボックスがチェックされているか？
    isConfRowChecked() {
      return (id) => {
        return this.alcoholCheckIdsToConfirm.findIndex((i) => i === id) >= 0
      }
    },
    // 一覧中の未確認のID
    unconfirmedAlcoholCheckIds() {
      return this.alcoholCheckDataList
        .filter((r) => !r.confirmed)
        .map((r) => r.id)
    },
    // 自由項目の必須
    freeItemRequiredIdList() {
      const list = []
      this.freeItemDefList.forEach(item => {
        if ((this.isStartTab && item.show_timing !== 2) || (this.isEndTab && item.show_timing !== 1)) {
          if (item.required_item === 1) list.push(item.id)
        }
      })
      return list
    },
  },
  watch: {
    branches: function(val) {
      if (val.length >= 1) this.refreshList()
    },
    branchIdSelected: function(val) {
      const vehicleResult = Vue.prototype.$api.send('get', 'vehicles/list/' + val + '/open')
      vehicleResult.then(response => {
        this.vehicles = response.map((item) => {
          if (this.concatVehicleNameAndNo && item.number) item.name = item.name + ',' + item.number
          return item
        })
      })
        .catch(error => {
          this.$notification['error']({
            message: error.status + ': 車両の取得に失敗しました。',
          })
        })
    },
  },
  created() {
    this.tabIndex = this.$route.params.tabIndex || '1'

    const branchesResult = Vue.prototype.$api.send('get', 'user/branches')
    branchesResult.then(response => {
      // 先頭に「全社」を追加
      this.branches = [{ id: 0, name: '全社' }].concat(response)
      this.branchId = Number(this.$route.params.branchId) || this.branches[0].id
    })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': 支店の取得に失敗しました。',
        })
      })

    const myResult = Vue.prototype.$api.send('get', 'user')
    myResult.then(myResponse => {
      this.myRole = myResponse.role
      this.myRollCallAble = myResponse.roll_call_able
      this.alcoholMeasurementHide = myResponse.alcohol_measurement_hide
      if (myResponse.role !== 3) {
        const usersResult = Vue.prototype.$api.send('get', 'acquirable_users')
        usersResult.then(response => {
          this.drivers = response
        })
          .catch(error => {
            this.$notification['error']({
              message: error.status + ': ユーザーの取得に失敗しました。',
            })
          })
      } else {
        this.drivers = [myResponse]
      }
    })

    const companyResult = Vue.prototype.$api.send('get', 'company')
    companyResult.then(response => {
      this.alcoholThreshold = response.alcohol_threshold
      this.concatVehicleNameAndNo = response.concat_vehicle_name_and_no
      this.canUpdateAlcoholMeasurementResult = response.can_update_alcohol_measurement_result
    })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': 会社情報の取得に失敗しました。',
        })
        this.companyLoading = false
      })

    const freeItemAlcoholCheckResult = Vue.prototype.$api.send('get', 'free_item_alcohol_checks')
    freeItemAlcoholCheckResult.then(response => {
      // console.log('freeItem: ', response)
      this.freeItemDefList = response
    })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': 自由項目の取得に失敗しました。',
        })
      })
  },
  filters: {
    momentDate: function (date) {
      return moment(date).format('YYYY/MM/DD')
    },
    momentTime: function (time) {
      return moment(time, 'YYYY-MM-DD hh:mm:ss').format('HH:mm')
    },
    formatMeasurement: function (value) {
      return Math.floor(value * 1000) / 1000
    },
  },
  methods: {
    showDateTime (datetime, isPrintTab) {
      if (datetime) {
        if (this.myRole === 0) return moment(datetime).format(isPrintTab ? 'MM/DD HH:mm:ss' : 'YYYY/MM/DD HH:mm:ss')
        return moment(datetime).format(isPrintTab ? 'MM/DD HH:mm' : 'YYYY/MM/DD HH:mm')
      }
      return ''
    },
    print() { window.print() },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    targetBranchChange(val) { this.branchId = val },
    targetDriverChange(val) { this.alcoholCheckForm.user_id = val },
    targetRollCallExecuterChange(val) { this.alcoholCheckForm.roll_call_executer_id = val },
    targetVehicleChange(val) { this.alcoholCheckForm.vehicle_id = val },
    yesterday() { this.targetDate = moment(this.targetDate).subtract(1, 'days') },
    today() { this.targetDate = moment() },
    tomorrow() { this.targetDate = moment(this.targetDate).add(1, 'days') },
    sortAlcoholCheck() {
      this.alcoholCheckDataList.sort(function(a, b) {
        if (a.confirmed < b.confirmed) return -1
        else if (a.confirmed > b.confirmed) return 1
        else if (a.confirmed === b.confirmed) {
          if (a.call_datetime < b.call_datetime) return -1
          else return 1
        }
        return 0
      })
    },
    // 一覧データ取得 (共通)
    refreshList() {
      this.loading = true
      this.branchIdSelected = this.branchId
      const bodyData = {
        date: this.gotDateHyphen,
        branch_id: this.branchId,
      }
      this.alcoholCheckIdsToConfirm = []

      let alcoholResult
      if (this.isStartTab) {
        alcoholResult = Vue.prototype.$api.send('get', 'alcohol/start', bodyData)
      } else if (this.isEndTab) {
        alcoholResult = Vue.prototype.$api.send('get', 'alcohol/end', bodyData)
      } else if (this.isPrintTab) {
        alcoholResult = Promise.all([
          Vue.prototype.$api.send('get', 'alcohol/start', bodyData),
          Vue.prototype.$api.send('get', 'alcohol/end', bodyData),
        ])
      } else {
        this.alcoholCheckDataList = []
        this.loading = false
        return
      }

      // APIレスポンスの自由項目データをdataIndexのプロパティにする
      const setFreeItemData = (freeItemAlcoholCheckContents, item, isEnd) => {
        const prefix = isEnd ? this.dataIndexPrefixOfFreeItemEnd : this.dataIndexPrefixOfFreeItem
        freeItemAlcoholCheckContents.data.forEach((fiacc) => {
          item[prefix + fiacc.free_item_alcohol_check_id] = fiacc.data
        })
      }

      alcoholResult.then(response => {
        if (this.isStartTab || this.isEndTab) {
          this.minHeight = 'max-content'
          this.alcoholCheckDataList = response.map((item) => {
            if (this.concatVehicleNameAndNo && item.vehicle_number) item.vehicle_name = item.vehicle_name + ',' + item.vehicle_number
            setFreeItemData(item.free_item_alcohol_check_contents, item)
            return item
          })
          this.sortAlcoholCheck()
        } else {
          const syncList = []
          this.alcoholCheckDataList = []

          response[0].forEach((item) => {
            if (this.concatVehicleNameAndNo && item.vehicle_number) item.vehicle_name = item.vehicle_name + ',' + item.vehicle_number
            setFreeItemData(item.free_item_alcohol_check_contents, item)

            syncList[item.user_id] = syncList[item.user_id] || {}
            syncList[item.user_id][item.vehicle_id] = syncList[item.user_id][item.vehicle_id] || []

            const len = syncList[item.user_id][item.vehicle_id].length
            syncList[item.user_id][item.vehicle_id].push({})
            syncList[item.user_id][item.vehicle_id][len].start = item
          })

          response[1].forEach((item) => {
            if (this.concatVehicleNameAndNo && item.vehicle_number) item.vehicle_name = item.vehicle_name + ',' + item.vehicle_number
            setFreeItemData(item.free_item_alcohol_check_contents, item, true)

            syncList[item.user_id] = syncList[item.user_id] || {}
            syncList[item.user_id][item.vehicle_id] = syncList[item.user_id][item.vehicle_id] || []

            let insertFlag = false
            const len = syncList[item.user_id][item.vehicle_id].length
            for (let i = 0; i < len; i++) {
              if (syncList[item.user_id][item.vehicle_id][i].end) {
                continue
              }
              syncList[item.user_id][item.vehicle_id][i].end = item
              insertFlag = true
              break
            }
            if (!insertFlag) {
              syncList[item.user_id][item.vehicle_id].push({})
              syncList[item.user_id][item.vehicle_id][len].end = item
            }
          })

          const nvl = (v) => v == null ? '' : v
          let rowCount = 0
          for (const userKey in syncList) {
            for (const vehicleKey in syncList[userKey]) {
              syncList[userKey][vehicleKey].forEach((it) => {
                const start = it.start || {}
                const end = it.end || {}
                const either = it.end || it.start || {}
                const contentData = {
                  id: nvl(either.id),
                  branch_name: nvl(either.branch_name),
                  user_name: nvl(either.user_name),
                  vehicle_name: nvl(either.vehicle_name),
                  item_allow_ride: nvl(start.item_allow_ride),
                  call_datetime: nvl(start.call_datetime),
                  alcohol_measurement_result: nvl(start.alcohol_measurement_result),
                  item_alcohol_detector: nvl(start.item_alcohol_detector),
                  item_drinking: nvl(start.item_drinking),
                  item_roll_call_type: nvl(start.item_roll_call_type),
                  item_call_excuter_id: nvl(start.item_call_excuter_id),
                  executer_name: nvl(start.executer_name),
                  call_datetime_end: nvl(end.call_datetime),
                  alcohol_measurement_result_end: nvl(end.alcohol_measurement_result),
                  item_alcohol_detector_end: nvl(end.item_alcohol_detector),
                  item_drinking_end: nvl(end.item_drinking),
                  item_roll_call_type_end: nvl(end.item_roll_call_type),
                  item_call_excuter_id_end: nvl(end.item_call_excuter_id),
                  executer_name_end: nvl(end.executer_name),
                }
                if (it.start) {
                  setFreeItemData(it.start.free_item_alcohol_check_contents, contentData)
                }
                if (it.end) {
                  setFreeItemData(it.end.free_item_alcohol_check_contents, contentData, true)
                }
                this.alcoholCheckDataList.push(contentData)
                rowCount++
              })
            }
          }
          this.minHeight = (rowCount * 82) + 'px'
        }
        this.gotDate = this.targetDate.format('YYYY/MM/DD')
        this.gotBranchName = this.branches.find(e => e.id === this.branchId).name
        this.loading = false
      })
        .catch(error => {
          this.$notification['error']({
            message: error.status + ': 点呼記録の取得に失敗しました。',
          })
          this.loading = false
        })
    },
    // 「確認済みにする」ボタンクリック時
    rollCallExecute() {
      if (!this.alcoholCheckIdsToConfirm.length) {
        return
      }

      this.loading = true
      const postData = {
        alcohol_check_ids: this.alcoholCheckIdsToConfirm,
      }
      const alcoholStartCheckResult = Vue.prototype.$api.send('post', 'alcohol/roll_call_execute', postData)
      alcoholStartCheckResult.then(response => {
        this.refreshList()
        this.$notification['success']({
          message: '点呼記録簿を更新しました。',
        })
      })
        .catch(error => {
          if (error.status === 405) {
            this.$notification['error']({
              message: error.data.data,
            })
          } else {
            this.$notification['error']({
              message: error.status + ': 点呼記録の更新に失敗しました。',
            })
          }
          this.loading = false
        })
    },
    // 作成/編集モーダルのキャンセルボタンクリック時
    handleCancelAlcoholCheckModal() { this.modalVisible = false },
    // 自由項目編集モーダルのキャンセルボタンクリック時
    handleCancelAlcoholCheckSelfModal() { this.modalVisibleSelf = false },
    // 一覧の上の作成ボタンクリック時
    createAlcoholCheck() {
      const now = moment()
      this.modalVisible = true
      this.alcoholCheckCreate = true
      this.targetAlcoholCheckId = 0
      this.createCallDate = now
      this.createCallTime = now
      this.alcoholCheckForm.vehicle_id = '0'
      this.alcoholCheckForm.user_id = '0'
      this.alcoholCheckForm.roll_call_executer_id = '0'
      this.alcoholCheckForm.item_alcohol_detector = 0
      this.alcoholCheckForm.item_drinking = 0
      this.alcoholCheckForm.item_roll_call_type = 0
      if (this.isStartTab) {
        this.alcoholCheckForm.type = 0
        this.alcoholCheckForm.item_allow_ride = 0
      } else {
        this.alcoholCheckForm.type = 1
      }
      this.alcoholCheckForm.free_items = {}
      this.modalText = '作成'
      this.isDeletable = false
    },
    // テーブルヘッダーの確認チェックボックス変更時
    onChangeConfirmedAll(e) {
      this.alcoholCheckIdsToConfirm = e.target.checked
        // すべてチェックON (unconfirmedAlcoholCheckIdsを複製)
        ? this.unconfirmedAlcoholCheckIds.slice(0, this.unconfirmedAlcoholCheckIds.length)
        // すべてチェックOFF
        : []
    },
    // 一覧行の確認チェックボックス変更時
    onChangeConfirmedRow(e, id) {
      if (e.target.checked) {
        this.alcoholCheckIdsToConfirm.push(id)
      } else {
        // チェックOFF: alcoholCheckIdsToConfirmから削除
        const index = this.alcoholCheckIdsToConfirm.findIndex((i) => i === id)
        if (index >= 0) {
          this.alcoholCheckIdsToConfirm.splice(index, 1)
        }
      }
    },
    // 一覧行の編集ボタンクリック時
    updateAlcoholCheck(id) {
      this.setPropertiesForUpdateModal(id, false)
      this.modalVisible = true
      this.modalText = '更新'
    },
    // 一覧行の自由項目編集ボタンクリック時
    updateAlcoholCheckSelf(id) {
      this.setPropertiesForUpdateModal(id, true)
      this.modalVisibleSelf = true
    },
    /// 編集/自由項目編集モーダルの表示設定
    setPropertiesForUpdateModal(id, isSelf) {
      const targetAlcoholCheck = this.alcoholCheckDataList.find(e => e.id === id)
      const callDateTime = moment(targetAlcoholCheck.call_datetime)
      this.alcoholCheckCreate = false
      this.targetAlcoholCheckId = id
      if (callDateTime._isValid) {
        this.createCallDate = callDateTime
        this.createCallTime = callDateTime
      } else {
        this.createCallDate = null
        this.createCallTime = null
      }
      this.onCallDate = callDateTime
      this.alcoholCheckForm.vehicle_id = targetAlcoholCheck.vehicle_id
      this.alcoholCheckForm.user_id = targetAlcoholCheck.user_id
      this.alcoholCheckForm.roll_call_executer_id = targetAlcoholCheck.roll_call_executer_id
      this.alcoholCheckForm.item_alcohol_detector = targetAlcoholCheck.item_alcohol_detector
      this.alcoholCheckForm.item_drinking = targetAlcoholCheck.item_drinking
      this.alcoholCheckForm.item_roll_call_type = targetAlcoholCheck.item_roll_call_type
      this.alcoholCheckForm.alcohol_measurement_result = targetAlcoholCheck.alcohol_measurement_result
      this.alcoholCheckForm.type = targetAlcoholCheck.type
      if (this.isStartTab) {
        this.alcoholCheckForm.item_allow_ride = targetAlcoholCheck.item_allow_ride
      }
      this.alcoholCheckForm.free_items = this.freeItemDefList.reduce((map, item) => {
        map[item.id] = targetAlcoholCheck[this.dataIndexPrefixOfFreeItem + item.id] || null
        return map
      }, {})
      this.isDeletable = !isSelf && (targetAlcoholCheck.manual_flag || this.myRole <= 2)
    },
    // 作成/編集モーダルの削除ボタンクリック時
    handleDeleteAlcoholCheck() {
      const actionUrl = this.isStartTab ? 'alcohol/start' : 'alcohol/end'
      const bodyData = {
        id: this.targetAlcoholCheckId,
      }
      const sendAlcoholCheck = Vue.prototype.$api.send('delete', actionUrl, bodyData)
      sendAlcoholCheck.then(response => {
        this.$notification['success']({
          message: '点呼記録簿を削除しました。',
        })
        this.alcoholCheckDataList = this.alcoholCheckDataList.filter(e => e.id !== this.targetAlcoholCheckId)
        this.modalVisible = false
        this.alcoholCheckConfirmLoading = false
      })
        .catch(error => {
          if (error.status === 406 || error.status === 405 || error.status === 403 || error.status === 400) {
            this.$notification['error']({
              message: error.data.data,
            })
          } else if (error.data) {
            for (const key in error.data.errors) {
              this.$refs[key].validateState = 'error'
              this.$refs[key].validateMessage = error.data.errors[key]
            }
          }
          this.alcoholCheckConfirmLoading = false
          console.error(error)
        })
    },
    // 自由項目のバリデーション
    validateFreeItem(onlyFreeItem = false) {
      for (const key in this.alcoholCheckForm.free_items) {
        const flag = this.freeItemRequiredIdList.includes(Number(key))
        if (flag && !this.alcoholCheckForm.free_items[key]) {
          const target = this.freeItemDefList.find(e => e.id === Number(key))
          if (!onlyFreeItem || target.edit_self_flag === 1) {
            this.$notification['error']({
              message: target.name + ' は必須項目です。',
            })
            return false
          }
        }
      }
      return true
    },
    // 作成/編集モーダルの作成/更新ボタンクリック時
    handleOkAlcoholCheck() {
      if (this.alcoholCheckForm.user_id === '0' || this.alcoholCheckForm.vehicle_id === '0') {
        this.$notification['error']({
          message: '運転者と車両は必須項目です。',
        })
        return
      }
      if (!this.validateFreeItem()) return

      this.alcoholCheckConfirmLoading = true
      let actionType = 'post'
      let actionUrl = this.isStartTab ? 'alcohol/start/create' : 'alcohol/end/create'
      const bodyData = {
        vehicle_id: this.alcoholCheckForm.vehicle_id,
        user_id: this.alcoholCheckForm.user_id,
        item_alcohol_detector: this.alcoholCheckForm.item_alcohol_detector,
        item_drinking: this.alcoholCheckForm.item_drinking,
        item_roll_call_type: this.alcoholCheckForm.item_roll_call_type,
        free_item_alcohol_check_contents: this.freeItemDefList.reduce(
          (obj, item) => {
            obj[item.id] = this.alcoholCheckForm.free_items[item.id]
            return obj
          }, {}),
      }
      if (this.isStartTab) {
        bodyData.item_allow_ride = this.alcoholCheckForm.item_allow_ride
      }
      if (this.myRole === 0 || this.canUpdateAlcoholMeasurementResult === 1) {
        bodyData.alcohol_measurement_result = this.alcoholCheckForm.alcohol_measurement_result
      }
      if (this.myRole === 0) {
        bodyData.date = this.onCallDate.format('YYYY-MM-DD')
        if (this.createCallDate && this.createCallTime) {
          bodyData.call_datetime = this.createCallDate.format('YYYY-MM-DD') + ' ' + this.createCallTime.format('HH:mm:ss')
          bodyData.type = this.alcoholCheckForm.type
        }
        bodyData.roll_call_executer_id = this.alcoholCheckForm.roll_call_executer_id
      }

      if (this.targetAlcoholCheckId !== 0) {
        actionType = 'put'
        actionUrl = this.isStartTab ? 'alcohol/start' : 'alcohol/end'
        bodyData.id = this.targetAlcoholCheckId
      } else {
        bodyData.call_datetime = this.createCallDate.format('YYYY-MM-DD') + ' ' + this.createCallTime.format('HH:mm:ss')
        bodyData.date = this.createCallDate.format('YYYY-MM-DD')
      }

      const sendAlcoholCheck = Vue.prototype.$api.send(actionType, actionUrl, bodyData)
      sendAlcoholCheck.then(response => {
        if (this.createCallDate && this.createCallDate.format('YYYY/MM/DD') !== this.gotDate) {
          this.refreshList()
        } else {
          const vehicle = this.vehicles.find(v => v.id === this.alcoholCheckForm.vehicle_id)
          if (this.targetAlcoholCheckId !== 0) {
            const targetUpdateAlcoholCheck = this.alcoholCheckDataList.find(e => e.id === this.targetAlcoholCheckId)
            targetUpdateAlcoholCheck.vehicle_id = this.alcoholCheckForm.vehicle_id
            targetUpdateAlcoholCheck.vehicle_name = response.vehicle_name
            if (this.concatVehicleNameAndNo && response.vehicle_number) targetUpdateAlcoholCheck.vehicle_name = response.vehicle_name + ',' + response.vehicle_number
            targetUpdateAlcoholCheck.user_id = this.alcoholCheckForm.user_id
            targetUpdateAlcoholCheck.user_name = response.user_name
            targetUpdateAlcoholCheck.executer_name = response.executer_name
            targetUpdateAlcoholCheck.call_datetime = response.call_datetime
            targetUpdateAlcoholCheck.confirmed = response.confirmed
            targetUpdateAlcoholCheck.item_alcohol_detector = this.alcoholCheckForm.item_alcohol_detector
            targetUpdateAlcoholCheck.item_drinking = this.alcoholCheckForm.item_drinking
            targetUpdateAlcoholCheck.item_roll_call_type = this.alcoholCheckForm.item_roll_call_type
            if (this.myRole === 0 || this.canUpdateAlcoholMeasurementResult === 1) {
              targetUpdateAlcoholCheck.alcohol_measurement_result = this.alcoholCheckForm.alcohol_measurement_result
            }
            if (this.isStartTab) {
              targetUpdateAlcoholCheck.item_allow_ride = this.alcoholCheckForm.item_allow_ride
            }
            this.freeItemDefList.forEach((item) => {
              targetUpdateAlcoholCheck[this.dataIndexPrefixOfFreeItem + item.id] = this.alcoholCheckForm.free_items[item.id]
            })
            this.$notification['success']({
              message: '点呼記録簿を更新しました。',
            })
          } else {
            if (this.createCallDate.format('YYYY/MM/DD') === this.gotDate) {
              this.freeItemDefList.forEach((item) => {
                response[this.dataIndexPrefixOfFreeItem + item.id] = this.alcoholCheckForm.free_items[item.id]
              })
              if (vehicle) {
                response.branch_name = vehicle.branch_name
              }
              if (this.concatVehicleNameAndNo && vehicle.number) response.vehicle_name = response.vehicle_name + ',' + vehicle.number
              this.alcoholCheckDataList.push(response)
            }
            this.$notification['success']({
              message: '点呼記録簿を作成しました。',
            })
          }
        }
        this.sortAlcoholCheck()
        this.modalVisible = false
        this.alcoholCheckConfirmLoading = false
      })
        .catch(error => {
          if (error.status === 406 || error.status === 405 || error.status === 403 || error.status === 400) {
            this.$notification['error']({
              message: error.data.data,
            })
          } else if (error.data) {
            for (const key in error.data.errors) {
              this.$refs[key].validateState = 'error'
              this.$refs[key].validateMessage = error.data.errors[key]
            }
          }
          this.alcoholCheckConfirmLoading = false
          console.error(error)
        })
    },
    /// 自由項目編集モーダルの更新ボタンクリック時
    handleOkAlcoholCheckSelf() {
      if (!this.validateFreeItem(true)) return
      this.alcoholCheckConfirmLoading = true
      const actionType = 'put'
      const actionUrl = 'alcohol/self'
      const bodyData = {
        id: this.targetAlcoholCheckId,
        free_item_alcohol_check_contents: this.freeItemDefList.reduce(
          (obj, item) => {
            obj[item.id] = this.alcoholCheckForm.free_items[item.id]
            return obj
          }, {}),
      }

      const sendAlcoholCheck = Vue.prototype.$api.send(actionType, actionUrl, bodyData)
      sendAlcoholCheck.then(response => {
        // console.log(response)
        const targetUpdateAlcoholCheck = this.alcoholCheckDataList.find(e => e.id === this.targetAlcoholCheckId)
        this.freeItemDefList.forEach((item) => {
          if (item.edit_self_flag) {
            targetUpdateAlcoholCheck[this.dataIndexPrefixOfFreeItem + item.id] = this.alcoholCheckForm.free_items[item.id]
          }
        })
        this.$notification['success']({
          message: '点呼記録簿を更新しました。',
        })
        this.sortAlcoholCheck()
        this.modalVisibleSelf = false
        this.alcoholCheckConfirmLoading = false
      })
        .catch(error => {
          if (error.status === 406 || error.status === 405 || error.status === 403 || error.status === 400) {
            this.$notification['error']({
              message: error.data.data,
            })
          } else if (error.data) {
            for (const key in error.data.errors) {
              this.$refs[key].validateState = 'error'
              this.$refs[key].validateMessage = error.data.errors[key]
            }
          }
          this.alcoholCheckConfirmLoading = false
          console.error(error)
        })
    },
    tabClick(value) {
      this.tabIndex = value
      this.refreshList()
    },
    changeExportDateStart(date) {
      this.exportDateStart = date
    },
    changeExportDateEnd(date) {
      this.exportDateEnd = date
    },
    csvExport() {
      this.exportLoading = true
      const _this = this
      var bodyData = {
        branch_id: this.branchId,
        date_start: this.exportDateStart.format('YYYY-MM-DD'),
        date_end: this.exportDateEnd.format('YYYY-MM-DD'),
      }
      const exportResult = Vue.prototype.$api.send('get', 'alcohol/export', bodyData)
      exportResult.then(response => {
        const detector = ['-', '連動検知器', 'その他の検知器']
        let csv = '\ufeff'
        csv += '日付,点呼日時,点呼執行者,支店名,運転手名,車両名,車両番号,乗車前後,乗車可否,アルコール検知器の有無,酒気帯びの有無,アルコール濃度,点呼方法,検知器の種類'
        this.freeItemDefList.forEach(item => { csv += ',' + item.name })
        csv += '\n'
        response.forEach(res => {
          const allowVal = res.type ? '-' : _this.itemAllowRide[res.item_allow_ride]
          csv += res.date + ',' + res.call_datetime + ',' + res.executer_name + ',' + res.branch_name + ',' + res.user_name + ',' + res.vehicle_name + ',' + res.vehicle_number + ',' + _this.itemType[res.type] + ',' + allowVal + ',' + _this.itemAlcoholDetector[res.item_alcohol_detector] + ',' + _this.itemDrinking[res.item_drinking] + ',' + res.alcohol_measurement_result + ',' + _this.itemRollCallType[res.item_roll_call_type] + ',' + detector[res.detector]
          this.freeItemDefList.forEach(item => {
            const fiacc = res.free_item_alcohol_check_contents.filter(data => data.free_item_alcohol_check_id === item.id)
            if (fiacc.length) csv += ',' + fiacc[0].data
            else csv += ','
          })
          csv += '\n'
        })
        var blob = new Blob([csv], { type: 'text/plain' })
        var link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'export_alcohol_checks.csv'
        link.click()
        this.exportLoading = false
      })
        .catch(error => {
          this.$notification['error']({
            message: error.status + ': 点呼記録簿の出力に失敗しました。',
          })
          this.exportLoading = false
        })
    },
    inputFreeItem(id, text) {
      if (!this.alcoholCheckForm.free_items[id]) this.alcoholCheckForm.free_items[id] = text
      else this.alcoholCheckForm.free_items[id] += text
      this.$forceUpdate()
    },
  },
}
</script>

<style>
@media print {
  header, .ant-layout-sider, .no-print, .ant-message, .ant-layout-footer {
    display: none;
  }
  @page { size: auto !important; }
  .ant-table thead.ant-table-thead {display: table-header-group;}
  .ant-table .ant-table-content .ant-table-body table tr, .ant-table .ant-table-content .ant-table-body table td {
    font-size: 11px !important;
  }
  .ant-table .ant-table-content .ant-table-body table th, .ant-table .ant-table-content .ant-table-body table td {
    padding: 1px 2px;
  }
  .ant-table { table-layout: auto; }
  .utils__content {
    padding: 0px 8px !important;
  }
  .ant-table-body { margin-bottom: 80px; }
  html {
    -webkit-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
  }
  .ant-table .ant-table-body .driver-name-cell {
    width: 110px !important;
  }
  .ant-table .ant-table-body .company-name-cell {
    width: 80px !important;
  }
}

.print-tab .ant-table-bordered .ant-table-thead > tr > th, body .print-tab .ant-table-wrapper tr td {
  border: 1px solid #e8e8e8 !important;
}
.print-tab .ant-table-bordered .ant-table-thead > tr > .border-left-thick, .print-tab .ant-table-bordered .ant-table-tbody > tr > .border-left-thick {
  border-left: 2.5px solid #e8e8e8 !important;
}
</style>
