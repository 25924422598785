<template>
  <div>
    <div v-if="loading" class="text-center">
      <a-spin tip="Loading..." />
    </div>
    <a-form-model
      v-if="!loading"
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item ref="name" label="ユーザー名" prop="name">
        <a-input
          v-model="form.name"
          @blur="
            () => {
              $refs.name.onFieldBlur()
            }
          "
        />
      </a-form-model-item>

      <a-form-model-item ref="phonetic" label="ふりがな" prop="phonetic">
        <a-input
          v-model="form.phonetic"
          @blur="
            () => {
              $refs.phonetic.onFieldBlur()
            }
          "
        />
      </a-form-model-item>

      <a-form-model-item v-if="submitType=='post'" ref="email" label="メールアドレス" prop="email">
        <a-input
          v-model="form.email"
          @blur="
            () => {
              $refs.email.onFieldBlur()
            }
          "
        />
      </a-form-model-item>
      <a-form-model-item label="権限" ref="role" prop="role">
        <a-select v-model="form.role" placeholder="権限を選択してください。">
          <a-select-option v-for="item in roles" :key="item.value">
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item label="所属支店" v-if="form.role >= 2">
        <a-checkbox-group
          v-model="form.belong_branches"
          :options="allBranches"
        />
      </a-form-model-item>

      <a-form-model-item ref="fleet_flag" label="解析対象" prop="fleet_flag" extra="ロガーからのデータはすべて解析されます。">
        <a-radio-group v-model="form.fleet_flag">
          <a-radio :value="0">解析対象にする</a-radio>
          <a-radio :value="1">解析対象にしない</a-radio>
        </a-radio-group>
      </a-form-model-item>

      <a-form-model-item ref="tel" label="電話番号" prop="tel">
        <a-input
          v-model="form.tel"
          @blur="
            () => {
              $refs.tel.onFieldBlur()
            }
          "
        />
      </a-form-model-item>
      <a-form-model-item ref="mobile_model" label="スマホ機種" prop="mobile_model">
        <a-input
          v-model="form.mobile_model"
          @blur="
            () => {
              $refs.mobile_model.onFieldBlur()
            }
          "
        />
      </a-form-model-item>
      <a-form-model-item ref="mobile_sim" label="スマホのSIM" prop="mobile_sim">
        <a-input
          v-model="form.mobile_sim"
          @blur="
            () => {
              $refs.mobile_sim.onFieldBlur()
            }
          "
        />
      </a-form-model-item>
      <a-form-model-item v-if="myRole === 0" ref="announcement_flag" label="NOWの使用" prop="announcement_flag">
        <a-radio-group v-model="form.announcement_flag">
          <a-radio :value="0">使用しない</a-radio>
          <a-radio :value="1">使用する</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item v-if="myRole === 0" ref="plus_flag" label="PLUSの使用" prop="plus_flag">
        <a-radio-group v-model="form.plus_flag">
          <a-radio :value="0">使用しない</a-radio>
          <a-radio :value="1">使用する</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item v-if="myRole === 0" ref="interlocking_detector" label="連動検知器の使用" prop="interlocking_detector">
        <a-radio-group v-model="form.interlocking_detector">
          <a-radio :value="0">使用しない</a-radio>
          <a-radio :value="1">使用する</a-radio>
        </a-radio-group>
      </a-form-model-item>

      <a-form-model-item ref="roll_call_able" label="点呼権限" prop="roll_call_able">
        <a-radio-group v-model="form.roll_call_able">
          <a-radio :value="0">権限に従う</a-radio>
          <a-radio :value="1">支店管理者と同等にする</a-radio>
        </a-radio-group>
      </a-form-model-item>

      <a-form-model-item v-if="myRole === 0" ref="create_operator_flag" label="代行オペレーター作成可能" prop="create_operator_flag">
        <a-radio-group v-model="form.create_operator_flag">
          <a-radio :value="0">オペレーターの作成は不可</a-radio>
          <a-radio :value="1">オペレーターの作成を可能</a-radio>
        </a-radio-group>
      </a-form-model-item>

      <a-form-model-item v-if="myCreateOperator === 1" ref="operator_flag" label="代行オペレーター" prop="operator_flag">
        <a-radio-group v-model="form.operator_flag">
          <a-radio :value="0">オペレーターにしない</a-radio>
          <a-radio :value="1">オペレーターにする</a-radio>
        </a-radio-group>
      </a-form-model-item>

      <a-form-model-item v-if="myRole === 0" ref="roll_call_agent" label="点呼代行対象" prop="roll_call_agent">
          <a-radio-group v-model="form.roll_call_agent">
          <a-radio :value="0">対象にしない</a-radio>
          <a-radio :value="1">対象にする</a-radio>
        </a-radio-group>
      </a-form-model-item>

      <a-config-provider v-if="myRole === 0" :locale="locale">
        <a-form-model-item ref="roll_call_date_start" label="点呼代行開始日" prop="roll_call_date_start">
          <a-date-picker placeholder="点呼代行開始日" v-model="rollCallDateStart" />
        </a-form-model-item>
      </a-config-provider>

      <a-config-provider v-if="myRole === 0" :locale="locale">
        <a-form-model-item ref="roll_call_date_end" label="点呼代行終了日" prop="roll_call_date_end">
          <a-date-picker placeholder="点呼代行終了日" v-model="rollCallDateEnd" />
        </a-form-model-item>
      </a-config-provider>

      <a-form-model-item v-if="myRole === 0" ref="roll_call_count" label="点呼代行回数/月" prop="roll_call_count" extra="点呼代行自体をしない場合は「0」にしておいてください。">
        <a-input-number v-model="form.roll_call_count" :min="0" />
      </a-form-model-item>

      <a-form-model-item v-if="myRole === 0" ref="roll_call_limited" label="代行利用制限" prop="roll_call_limited" extra="点呼代行ではないユーザーには影響しません。">
        <a-radio-group v-model="form.roll_call_limited">
          <a-radio :value="0">全ての点呼を代行にする</a-radio>
          <a-radio :value="1">平日の日中は代行にしない</a-radio>
          <a-radio :value="2">土日祝は代行にしない</a-radio>
        </a-radio-group>
      </a-form-model-item>

      <a-form-model-item v-if="myRole <= 2 && isGoogle2fa === 1" ref="is_google2fa" label="2段階認証" prop="is_google2fa">
        <a-radio-group v-model="form.is_google2fa">
          <a-radio :value="0">無効</a-radio>
          <a-radio :value="1">有効</a-radio>
        </a-radio-group>
      </a-form-model-item>

      <a-form-model-item ref="allow_driving_schedule" label="運行計画表の被閲覧" prop="allow_driving_schedule" v-if="form.plus_flag === 1">
        <a-radio-group v-model="form.allow_driving_schedule">
          <a-radio :value="0">許可しない</a-radio>
          <a-radio :value="1">許可する</a-radio>
        </a-radio-group>
      </a-form-model-item>

      <a-form-model-item v-if="myRole === 0" ref="use_violation_map" label="違反マップの使用" prop="use_violation_map">
          <a-radio-group v-model="form.use_violation_map">
          <a-radio :value="0">使用しない</a-radio>
          <a-radio :value="1">使用する</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item v-if="myRole === 0" ref="hide_menu" label="メニューを隠すか" prop="hide_menu">
          <a-radio-group v-model="form.hide_menu">
          <a-radio :value="0">隠さない</a-radio>
          <a-radio :value="1">隠す</a-radio>
        </a-radio-group>
      </a-form-model-item>

      <div v-for="item in freeItemUsers" :key="item.id">
        <a-form-model-item :label="item.name">
          <a-input
            v-model="freeItemValues[item.id]"
          />
        </a-form-model-item>
      </div>

      <a-form-model-item v-if="myRole === 0" ref="worker" label="首都高の作業員" prop="worker">
          <a-radio-group v-model="form.worker">
          <a-radio :value="0">作業員にしない</a-radio>
          <a-radio :value="1">作業員にする</a-radio>
        </a-radio-group>
      </a-form-model-item>

      <a-form-model-item v-if="myRole === 0 && user.company_experiment_demo === 1" ref="experiment_demo" label="首都高のアナウンス対象" prop="experiment_demo">
          <a-radio-group v-model="form.experiment_demo">
          <a-radio :value="0">対象にしない</a-radio>
          <a-radio :value="1">対象にする</a-radio>
        </a-radio-group>
      </a-form-model-item>

      <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
        <a-button type="primary" @click="onSubmit" :class="$style.filledBtn">
          {{ submitString }}
        </a-button>
        <router-link :to="backPath">
          <a-button style="margin-left: 10px;">
            戻る
          </a-button>
        </router-link>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>
<style lang="scss" module>
@import '@/assets/styles/mixins.scss';
</style>
<script>

import Vue from 'vue'
import { objectData } from '@/services/object'
import { enumData } from '@/services/enum'
import moment from 'moment'
import jaJa from 'ant-design-vue/es/locale/ja_JP'

export default {
  name: 'FormUser',
  data() {
    return {
      locale: jaJa,
      loading: true,
      submitType: this.$route.meta.submitType,
      submitString: this.$route.meta.submitString,
      backPath: '/company',
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
      user: Object.assign({}, objectData.user),
      allBranches: [],
      belongBranches: [],
      form: {
        role: undefined,
        name: '',
        phonetic: '',
        tel: '',
        mobile_model: '',
        mobile_sim: '',
        fleet_flag: 0,
        announcement_flag: 0,
        plus_flag: 0,
        interlocking_detector: 0,
        operator_flag: 0,
        roll_call_agent: 0,
        roll_call_date_start: '',
        roll_call_date_end: '',
        roll_call_count: 0,
        roll_call_limited: 0,
        create_operator_flag: 0,
        roll_call_able: 0,
        is_google2fa: 0,
        allow_driving_schedule: 0,
        use_violation_map: 0,
        hide_menu: 0,
        belong_branches: [],
        experiment_demo: 0,
        worker: 0,
      },
      rollCallDateStart: '',
      rollCallDateEnd: '',
      freeItemUsers: [],
      freeItemValues: {},
      roles: enumData.userRole,
      isGoogle2fa: 0,
      myRole: 3,
      myCreateOperator: 0,
    }
  },
  computed: {
    rules: function() {
      return {
        name: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
          { max: 255, message: '255文字までです。', trigger: 'blur' },
        ],
        phonetic: [
          { required: this.form.roll_call_agent === 1, message: '必須項目です。', trigger: 'blur' },
          { max: 255, message: '255文字までです。', trigger: 'blur' },
        ],
        email: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
          { type: 'email', message: 'メールアドレスを入力してください。', trigger: 'blur' },
        ],
        role: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
        ],
        fleet_flag: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
        ],
        tel: [
          { required: this.form.roll_call_agent === 1, message: '必須項目です。', trigger: 'blur' },
          { max: 99, message: '99文字までです。', trigger: 'blur' },
        ],
        mobile_model: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
          { max: 255, message: '255文字までです。', trigger: 'blur' },
        ],
        mobile_sim: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
          { max: 255, message: '255文字までです。', trigger: 'blur' },
        ],
        roll_call_able: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
        ],
        allow_driving_schedule: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
        ],
      }
    },
  },
  watch: {
    user: function(val) {
      this.form.name = val.name
      this.form.phonetic = val.phonetic
      this.form.role = val.role
      this.form.fleet_flag = val.fleet_flag
      this.form.tel = val.tel
      this.form.mobile_model = val.mobile_model
      this.form.mobile_sim = val.mobile_sim
      this.form.announcement_flag = val.announcement_flag
      this.form.plus_flag = val.plus_flag
      this.form.interlocking_detector = val.interlocking_detector_user_setting
      this.form.operator_flag = val.operator_flag
      this.form.roll_call_agent = val.roll_call_agent
      this.form.roll_call_date_start = val.roll_call_date_start
      this.form.roll_call_date_end = val.roll_call_date_end
      this.form.roll_call_count = val.roll_call_count
      this.form.roll_call_limited = val.roll_call_limited
      this.form.create_operator_flag = val.create_operator_flag
      this.form.roll_call_able = val.roll_call_able
      this.form.is_google2fa = val.is_google2fa
      this.form.allow_driving_schedule = val.allow_driving_schedule
      this.form.use_violation_map = val.use_violation_map
      this.form.hide_menu = val.hide_menu
      this.form.worker = val.worker
      this.form.experiment_demo = val.experiment_demo_form
      if (val.roll_call_date_start) this.rollCallDateStart = moment(val.roll_call_date_start, 'YYYY-MM-DD')
      if (val.roll_call_date_end) this.rollCallDateEnd = moment(val.roll_call_date_end, 'YYYY-MM-DD')
    },
    myRole: function(val) {
      this.roles = this.roles.filter(r => r.value >= val)
    },
  },
  methods: {
    onSubmit() {
      const _this = this
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.form.roll_call_date_start = this.rollCallDateStart ? this.rollCallDateStart.format('YYYY-MM-DD') : null
          this.form.roll_call_date_end = this.rollCallDateEnd ? this.rollCallDateEnd.format('YYYY-MM-DD') : null
          var formData = this.form
          formData['freeItems'] = this.freeItemValues
          if (_this.submitType === 'post') {
            if (formData.role >= 2 && formData.belong_branches.length === 0) {
              _this.$notification['error']({
                message: '所属支店を少なくとも1つは選択してください。',
              })
              return
            }

            const send = Vue.prototype.$api.send('post', 'users', formData)
            send.then(response => {
              _this.$notification['success']({
                message: this.form.name + ' を作成しました。',
              })
              _this.$router.push(this.backPath)
            })
              .catch(error => {
                if (error.status === 400 || error.status === 403) {
                  _this.$notification['error']({
                    message: error.data.data,
                  })
                } else {
                  for (var key in error.data.errors) {
                    _this.$refs[key].validateState = 'error'
                    _this.$refs[key].validateMessage = error.data.errors[key]
                  }
                }
                console.log(error)
              })
          } else if (_this.submitType === 'put') {
            const send = Vue.prototype.$api.send('put', 'users/' + _this.$route.params.id, formData)
            send.then(response => {
              // console.log(response)
              _this.$notification['success']({
                message: this.form.name + ' を更新しました。',
              })
              _this.$router.push('/users/' + _this.$route.params.id)
            })
              .catch(error => {
                if (error.status === 400 || error.status === 403) {
                  _this.$notification['error']({
                    message: error.data.data,
                  })
                } else {
                  for (var key in error.data.errors) {
                    _this.$refs[key].validateState = 'error'
                    _this.$refs[key].validateMessage = error.data.errors[key]
                  }
                }
                console.log(error)
              })
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
  },
  created() {
    const _this = this

    const myResult = Vue.prototype.$api.send('get', 'user')
    myResult.then(response => {
      _this.myRole = response.role
      _this.myCreateOperator = response.create_operator_flag
      _this.roles = this.roles.filter(r => r.value >= response.role)
    })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': 自身の情報取得に失敗しました。',
        })
      })

    const resultAllBranches = Vue.prototype.$api.send('get', 'branches')
    resultAllBranches.then(response => {
      this.allBranches = response.map((item) => { return { label: item.name, value: item.id } })
    })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': 支店の取得に失敗しました。',
        })
      })

    const resultFreeItem = Vue.prototype.$api.send('get', 'free_item_users')
    resultFreeItem.then(response => {
      this.freeItemUsers = response
    })
      .catch(error => {
        _this.$notification['error']({
          message: error.status + ': ユーザーの自由項目の取得に失敗しました。',
        })
        this.loading = false
      })

    if (this.submitType === 'put') {
      const result = Vue.prototype.$api.send('get', 'users/' + this.$route.params.id)
      result.then(response => {
        this.loading = false
        this.user = response
        this.isGoogle2fa = response.is_google2fa
        this.backPath = '/users/' + this.$route.params.id
        response.free_item_user_content.data.map(v => {
          _this.freeItemValues[v.free_item_user_id] = v.data
        })
      })
        .catch(error => {
          _this.$notification['error']({
            message: error.status + ': ユーザーデータの取得に失敗しました。',
          })
          this.loading = false
        })

      const resultBranchUsers = Vue.prototype.$api.send('get', 'users/' + this.$route.params.id + '/branches')
      resultBranchUsers.then(response => {
        this.form.belong_branches = response.map((item) => { return item.id })
      })
        .catch(error => {
          this.$notification['error']({
            message: error.status + ': 所属支店の取得に失敗しました。',
          })
          this.loading++
        })
    } else {
      this.loading = false
    }
  },
}
</script>
