<template>
  <div :class="!settings.isMobileView ? $style.desktopProfileMenuContainer : ''">
    <a-dropdown
      :trigger="['click']"
      placement="bottomRight"
      :overlayClassName="$style.ProfileMenu">
      <div :class="$style.dropdown">
        <div v-if="settings.isMobileView">
          <a-avatar shape="square" icon="user" :size="28" :class="$style.avatarMobile"/>
          <a-icon type="down" :class="$style.dropIconMobile"/>
        </div>
        <span v-if="!settings.isMobileView">
          <a-avatar shape="square" icon="user" :size="28" :class="$style.avatar"/>
          <span :class="$style.nameInTopbar">{{ name }}</span>
          <a-icon type="down-circle" :class="$style.dropIcon"/>
        </span>
      </div>
      <a-menu slot="overlay">
        <a-menu-item :class="$style.nameTag">
          <div :class="$style.versionText">Ver. 4.2.27</div>
          <div :class="$style.nameText">{{ name }}</div>
        </a-menu-item>
        <a-menu-divider/>
        <a-menu-item :class="$style.profileMenuItem">
          <router-link :to="{ name: '2faShow' }">
            <i :class="$style.menuIcon" class="icmn-qrcode mr-2"/>
            <span>2段階認証設定</span>
          </router-link>
        </a-menu-item>
        <a-menu-item :class="$style.profileMenuItem">
          <a href="https://tayori.com/q/aicontact-support/" target="_BLANK">
            <i class="fa fa-question-circle-o mr-2"></i>
            ヘルプ
            <i class="fa fa-external-link"></i>
          </a>
        </a-menu-item>
        <a-menu-item :class="$style.profileMenuItem">
          <a @click="emailChangeRequest">
            <i :class="$style.menuIcon" class="icmn-envelop"></i> メールアドレス変更
          </a>
        </a-menu-item>
        <a-menu-item :class="$style.profileMenuItem">
          <a @click="passwordChangeRequest">
            <i :class="$style.menuIcon" class="icmn-lock"></i> パスワード変更
          </a>
        </a-menu-item>
        <a-menu-item :class="$style.profileMenuItem">
          <a @click="logout">
            <i :class="$style.menuIcon" class="icmn-exit"></i> Logout
          </a>
        </a-menu-item>
      </a-menu>
    </a-dropdown>
    <a-modal
      title="パスワード変更"
      :visible="modalPass.visible"
      :confirm-loading="modalPass.confirmLoading"
      @ok="passHandleOk"
      @cancel="passHandleCancel"
    >
      <p>{{ modalPass.modalText }}</p>
    </a-modal>
    <a-modal
      title="メールアドレス変更"
      :visible="modalEmail.visible"
      :confirm-loading="modalEmail.confirmLoading"
      @ok="emailHandleOk"
      @cancel="emailHandleCancel"
    >
      <a-form-model
        layout="vertical"
        ref="ruleForm"
        :model="form"
        :rules="rules"
      >
        <a-form-model-item ref="email" label="新規メールアドレス" prop="email">
          <a-input
            v-model="form.email"
            @blur="
              () => {
                $refs.email.onFieldBlur()
              }
            "
          />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  data: function () {
    return {
      modalPass: {
        visible: false,
        confirmLoading: false,
        modalText: 'パスワードを変更しますか？',
      },
      modalEmail: {
        visible: false,
        confirmLoading: false,
      },
      form: {
        email: '',
      },
      rules: {
        email: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
          { type: 'email', message: 'メールアドレスを入力してください。', trigger: 'blur' },
        ],
      },
    }
  },
  computed: {
    name() { return this.$store.getters.name },
    email() { return this.$store.getters.email },
  },
  props: {
    settings: Object,
  },
  methods: {
    logout() {
      const _this = this
      const result = Vue.prototype.$auth.logout()
      result.then(function(response) {
        _this.$notification['success']({
          message: 'ログアウトしました',
        })
        _this.$router.push('/user/login')
      })
    },
    mailChangeRequest() {
      console.log('mail change request')
    },
    passwordChangeRequest() {
      console.log('password change request')
      this.modalPass.visible = true
    },
    passHandleOk(e) {
      this.modalPass.modalText = 'パスワード変更のためのURLをメールしています。'
      this.modalPass.confirmLoading = true
      const result = Vue.prototype.$api.send('post', 'auth/password_reset')
      result.then(response => {
        console.log(response)
        setTimeout(() => {
          this.modalPass.visible = false
          this.modalPass.confirmLoading = false
          this.modalPass.modalText = 'パスワードを変更しますか？'
        }, 1000)
      })
    },
    passHandleCancel(e) {
      this.modalPass.visible = false
    },
    emailChangeRequest() {
      console.log('email change request')
      this.modalEmail.visible = true
    },
    emailHandleOk(e) {
      const _this = this
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.modalEmail.confirmLoading = true
          const result = Vue.prototype.$api.send('post', 'auth/mail_update_request', this.form)
          result.then(response => {
            console.log(response)
            _this.$notification['success']({
              message: 'メールアドレスの変更の確認メールを送りました。',
            })
            this.modalEmail.visible = false
            this.modalEmail.confirmLoading = false
            this.form.email = ''
          })
        } else {
          console.log('error submit!!')
        }
      })
    },
    emailHandleCancel(e) {
      this.modalEmail.visible = false
    },
  },
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
